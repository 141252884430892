import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { farmsConfig } from 'config/constants'
import useRefresh from 'hooks/useRefresh'
import { useBNBBusdPrice } from 'hooks/useBUSDPrice'
import { deserializeToken } from 'state/user/hooks/helpers'
import tokens from 'config/constants/tokens'

// import pools from 'config/constants/pools';

import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.'
import { State, SerializedFarm, DeserializedFarmUserData, DeserializedFarm, DeserializedFarmsState, DeserializedPool } from '../types';

// import { State, SerializedFarm, DeserializedFarmUserData, DeserializedFarm, DeserializedFarmsState, DeserializedPoolsState, SerializedPool, DeserializedPool, DeserializedPoolUserData } from '../types'


const deserializeFarmUserData = (farm: SerializedFarm): DeserializedFarmUserData => {
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  }
}

const deserializeFarm = (farm: SerializedFarm): DeserializedFarm => {
  const {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    quoteTokenPriceBusd,
    tokenPriceBusd,
    isTokenOnly,
    isPackage,
    isMonthlyReward,
    monthlyReward,
  } = farm
  return {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    quoteTokenPriceBusd,
    tokenPriceBusd,
    token: deserializeToken(farm.token),
    quoteToken: deserializeToken(farm.quoteToken),
    userData: deserializeFarmUserData(farm),
    tokenAmountTotal: farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO,
    lpTotalInQuoteToken: farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO,
    lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
    tokenBalance: farm.tokenBalance ? new BigNumber(farm.tokenBalance) : BIG_ZERO,
    tokenPriceVsQuote: farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO,
    poolWeight: farm.poolWeight ? new BigNumber(farm.poolWeight) : BIG_ZERO,
    isTokenOnly,
    isPackage,
    isMonthlyReward,
    monthlyReward,
  }
}

// const deserializePoolUserData = (pools: SerializedPool): DeserializedPoolUserData => {
//   return {
//     allowance: pools.userData ? new BigNumber(pools.userData.allowance) : BIG_ZERO,
//     tokenBalance: pools.userData ? new BigNumber(pools.userData.tokenBalance) : BIG_ZERO,
//     stakedBalance: pools.userData ? new BigNumber(pools.userData.stakedBalance) : BIG_ZERO,
//     earnings: pools.userData ? new BigNumber(pools.userData.earnings) : BIG_ZERO,
//     stakingTokenBalance: pools.userData ? new BigNumber(pools.userData.stakingTokenBalance) : BIG_ZERO,
//     pendingReward: pools.userData ? new BigNumber(pools.userData.pendingReward) : BIG_ZERO,
//   }
// }

// const deserializedPool = (pools: SerializedPool): DeserializedPool => {
//   const {
//     lpAddresses,
//     lpSymbol,
//     pid,
//     dual,
//     multiplier,
//     isCommunity,
//     quoteTokenPriceBusd,
//     tokenPriceBusd,
//     isTokenOnly,
//     isPackage,
//     isMonthlyReward,
//     monthlyReward,
//   } = pools
//   return {
//     lpAddresses,
//     lpSymbol,
//     pid,
//     dual,
//     multiplier,
//     isCommunity,
//     quoteTokenPriceBusd,
//     tokenPriceBusd,
//     token: deserializeToken(pools.token),
//     quoteToken: deserializeToken(pools.quoteToken),
//     userData: deserializePoolUserData(pools),
//     tokenAmountTotal: pools.tokenAmountTotal ? new BigNumber(pools.tokenAmountTotal) : BIG_ZERO,
//     lpTotalInQuoteToken: pools.lpTotalInQuoteToken ? new BigNumber(pools.lpTotalInQuoteToken) : BIG_ZERO,
//     lpTotalSupply: pools.lpTotalSupply ? new BigNumber(pools.lpTotalSupply) : BIG_ZERO,
//     tokenBalance: pools.tokenBalance ? new BigNumber(pools.tokenBalance) : BIG_ZERO,
//     tokenPriceVsQuote: pools.tokenPriceVsQuote ? new BigNumber(pools.tokenPriceVsQuote) : BIG_ZERO,
//     poolWeight: pools.poolWeight ? new BigNumber(pools.poolWeight) : BIG_ZERO,
//     isTokenOnly,
//     isPackage,
//     isMonthlyReward,
//     monthlyReward,
//   }
// }

export const usePollFarmsPublicData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))
  }, [includeArchive, dispatch, slowRefresh])
}

export const usePollFarmsWithUserData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

/**
 * Fetches the "core" farm data used globally
 * 1 = CHEESE-BNB LP
 * 2 = BUSD-BNB LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync([1, 2]))
  }, [dispatch, fastRefresh])
}

export const useFarms = (): DeserializedFarmsState => {
  const farms = useSelector((state: State) => state.farms)  
  const deserializedFarmsData = farms.data.map(deserializeFarm)
  const { loadArchivedFarmsData, userDataLoaded } = farms
  return {
    loadArchivedFarmsData,
    userDataLoaded,
    data: deserializedFarmsData,
  }
}

// EDITED BELOW

// export const usePools = (): DeserializedPoolsState => {
//   const pools = useSelector((state: State) => state.pools)
//   console.log("pools here ",pools)
//   const deserializedPoolsData = pools.data.map(deserializedPool)
//   const { loadArchivedPoolsData, userDataLoaded } = pools
//   return {
//     loadArchivedPoolsData,
//     userDataLoaded,
//     data: deserializedPoolsData,
//   }
// }



export const useFarmFromPid = (pid: number): DeserializedFarm => {
  const farm = useSelector((state: State) => {
    // console.log(
    //   'state',
    //   state.farms.data.find((f) => f.pid === pid),
    // )
    return state.farms.data.find((f) => f.pid === pid)
  })
  // console.log("farms data here ", farm)


  return deserializeFarm(farm)
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return deserializeFarm(farm)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
  const { userData } = useFarmFromPid(pid)
  // console.log("userData =>",userData)
  const { allowance, tokenBalance, stakedBalance, earnings } = userData

  return {
    allowance,
    tokenBalance,
    stakedBalance,
    earnings,
  }
}

// EDITED BELOW
// EDITED BELOW

// export const usePoolFromPid = (pid: number): DeserializedPool => {
//   const pool = useSelector((state: State) => {
//     // console.log(
//       //   'state',
//       //   state.farms.data.find((f) => f.pid === pid),
//       // )
//       return state.pools.data.find((f) => f.pid === pid)
//     })
    
//     return deserializedPool(pool)
//   }
  
//   // EDITED BELOW
// export const usePoolUser = (pid): DeserializedPoolUserData => {
//   const { userData } = usePoolFromPid(pid)
//   console.log("usePoolUser ",userData)
//   // console.log("userData =>",userData)
//   const { allowance, tokenBalance, stakedBalance, earnings } = userData

//   return {
//     allowance,
//     tokenBalance,
//     stakedBalance,
//     earnings,
//   }
// }

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid)
  return farm && new BigNumber(farm.tokenPriceBusd)
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid)
  let lpTokenPrice = BIG_ZERO

  if (farm.lpTotalSupply.gt(0) && farm.lpTotalInQuoteToken.gt(0)) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(farm.lpTotalSupply)
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePriceCakeBusd = (): BigNumber => {
  const cakeBnbFarm = useFarmFromPid(1)

  const cakePriceBusdAsString = cakeBnbFarm.tokenPriceBusd

  const cakePriceBusd = useMemo(() => {
    return new BigNumber(cakePriceBusdAsString)
  }, [cakePriceBusdAsString])

  return cakePriceBusd
}

export const usePriceHamsterBusd = (): BigNumber => {
  const hamsterBnbFarm = useFarmFromPid(4)

  const hamsterPriceBusdAsString = hamsterBnbFarm.tokenPriceBusd

  const hamsterPriceBusd = useMemo(() => {
    return new BigNumber(hamsterPriceBusdAsString)
  }, [hamsterPriceBusdAsString])

  return hamsterPriceBusd
}

export const useTotalValue = (): BigNumber => {
  const { data: farms, userDataLoaded } = useFarms()

  const bnbPrice = useBNBBusdPrice()
  const cakePrice = usePriceCakeBusd()
  // const hamsterPrice = usePriceHamsterBusd()

  let value = new BigNumber(0)
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val
      if (farm.quoteToken.symbol === tokens.bnb.symbol || farm.quoteToken.symbol === tokens.wbnb.symbol) {
        if (bnbPrice) {
          val = new BigNumber(bnbPrice.toFixed()).times(farm.lpTotalInQuoteToken)
        } else {
          val = 0
        }
      } else if (farm.quoteToken.symbol === tokens.cake.symbol) {
        val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteToken.symbol === tokens.hamster.symbol) {
        // val = hamsterPrice.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }
      value = value.plus(val)
    }
  }
  return value
}
