import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: serializedTokens.cake,
  //   earningToken: serializedTokens.cake,
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x3a08D7C4D2C25cd22b8f2A0aB3b2c6841d5d42Ad',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 1,
  //   isFinished: false,
  // },

  // {
  //   sousId: 1,
  //   stakingToken: serializedTokens.hamster,
  //   earningToken: serializedTokens.cake,
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x3a08D7C4D2C25cd22b8f2A0aB3b2c6841d5d42Ad',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 2,
  //   isFinished: false,
  // },
  // {
  //   sousId: 2,
  //   stakingToken: serializedTokens.cheeselp,
  //   earningToken: serializedTokens.cheeselp,
  //   contractAddress: {
  //     97: '',
  //     56: '0x1Af58d879d6B6BDE8a4cC8Ed23005Ad5Ad1e94DC',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 3,
  //   isFinished: false,
  // },
  // {
  //   sousId: 3,
  //   stakingToken: serializedTokens.cakelp2,
  //   earningToken: serializedTokens.cheeselp,
  //   contractAddress: {
  //     97: '',
  //     56: '0xAf3D7692ba17510E262207d421DA82E7f68F9756',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 4,
  //   isFinished: false,
  // },
  // {
  //   sousId: 4,
  //   stakingToken: serializedTokens.cakelp2,
  //   earningToken: serializedTokens.cheeselp,
  //   contractAddress: {
  //     97: '',
  //     56: '0x1962735C32FB79b2a9A23217b63C1afc6E0be7C8',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 5,
  //   isFinished: false,
  // },
  // {
  //   sousId: 5,
  //   stakingToken: serializedTokens.cakelp,
  //   earningToken: serializedTokens.cheeselp,
  //   contractAddress: {
  //     97: '',
  //     56: '0x603C9D45e21bA5abe27d3AB2B3DA254A98d42C02',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 6,
  //   isFinished: false,
  // },







// ORIGINAL HERE
  {
    sousId: 6,
    stakingToken: serializedTokens.hamster,
    earningToken: serializedTokens.cheeselp,
    contractAddress: {
      97: '',
      56: '0x09DB126aFdE136f94B02c0A7A7D9De4C7A3F97D5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '40',
    sortOrder: 7,
    isFinished: false,
  },

  // EDITED BELOW
  // {
  //   sousId: 6,
  //   pid: 6,
  //   stakingToken: serializedTokens.hamster,
  //   earningToken: serializedTokens.cheeselp,
  //   token: serializedTokens.hamster,
  //   quoteToken: serializedTokens.cheeselp,
  //   contractAddress: {
  //     97: '',
  //     56: '0x09DB126aFdE136f94B02c0A7A7D9De4C7A3F97D5',
  //   },
  //   lpAddresses: {
  //     97: '',
  //     56: '0x09DB126aFdE136f94B02c0A7A7D9De4C7A3F97D5',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 7,
  //   isFinished: false,
  // },































  // {
  //   sousId: 7,
  //   stakingToken: serializedTokens.pancakelp,
  //   earningToken: serializedTokens.cheeselp,
  //   contractAddress: {
  //     97: '',
  //     56: '0xF90423A390483e5EFB14b966a011019114E6BcCA',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '40',
  //   sortOrder: 8,
  //   isFinished: false,
  // },

  // {
  //   sousId: 11,
  //   lpSymbol: 'CHEESE-CHEESE',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1Af58d879d6B6BDE8a4cC8Ed23005Ad5Ad1e94DC',
  //   },
  //   token: serializedTokens.cheeselp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,
  // },

  // {
  //   sousId: 2,
  //   stakingToken: serializedTokens.hamster,
  //   earningToken: serializedTokens.cheeselp,
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x71EDa46Dc456561F6b1a498646B8eA58bB89CFf6',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '',
  //   sortOrder: 2,
  //   isFinished: false,
  // },
]

export default pools
