import cheeseLpAbi from 'config/abi/cheeselp.json'
import panCheeseLpAbi from 'config/abi/cheeselp2.json'
import panCheeseLp2Abi from 'config/abi/cheeselp3.json'
import panCakeLpAbi from 'config/abi/cheeselp4.json'
import panCakeLpAbi2 from 'config/abi/cheeselp5.json'
import cheeseAbi from 'config/abi/cheese.json'
import hamsterAbi from 'config/abi/hamster.json'
import cakelp2 from 'config/abi/cakelp2.json'
import panCakeLp2Abi from 'config/abi/pancakelp2.json'
import cheesePanLpAbi from 'config/abi/cheesePanLpAbi.json'
import cheeseCheeseLpAbi from 'config/abi/cheeseCheeseLpAbi.json'
import cheeseCheeseLpAbi2 from 'config/abi/cheeseCheeseLpAbi2.json'
import cheesePan3Abi from 'config/abi/cheesepan3.json'
import HamsterNFTABI from 'config/abi/HamsterNFTABI.json'
import newStaking1 from 'config/abi/newStaking1.json'
import newStaking2 from 'config/abi/newStaking2.json'
import newStaking3 from 'config/abi/newStaking3.json'
import newStaking4 from 'config/abi/newStaking4.json'
import newStaking5 from 'config/abi/newStaking5.json'
import newStaking6 from 'config/abi/newStaking6.json'
import newStaking7 from 'config/abi/newStaking7.json'
import newStaking8 from 'config/abi/newStaking8.json'
import newStaking9 from 'config/abi/newStaking9.json'
import newStaking10 from 'config/abi/newStaking10.json'
import newStaking11 from 'config/abi/newStaking11.json'
import newStaking13 from 'config/abi/newStaking13.json'
import newStaking14 from 'config/abi/newStaking14.json'
import newStaking15 from 'config/abi/newStaking15.json'
import newStaking16 from 'config/abi/newStaking16.json'
import newStaking17 from 'config/abi/newStaking17.json'
import newStaking18 from 'config/abi/newStaking18.json'
import newStaking19 from 'config/abi/newStaking19.json'
import newStaking20 from 'config/abi/newStaking20.json'
import newStaking21 from 'config/abi/newStaking21.json'

import newStaking24 from 'config/abi/newStaking24.json'
import newStaking25 from 'config/abi/newStaking25.json'
// import contract from 'config/abi/contract.json'

export const getContractAbi = (address) => {
  switch (address) {
    case '0x71EDa46Dc456561F6b1a498646B8eA58bB89CFf6':
      return cheeseLpAbi

    case '0x09DB126aFdE136f94B02c0A7A7D9De4C7A3F97D5':
      return panCheeseLpAbi

    case '0xF90423A390483e5EFB14b966a011019114E6BcCA':
      return panCheeseLp2Abi

    case '0x99bF9cC0154655C5e913b752984A92Ad391aFE32':
      return panCakeLpAbi

    case '0x1962735C32FB79b2a9A23217b63C1afc6E0be7C8':
      return panCakeLpAbi2

    case '0x08Aed8578dAaBf12d48031fA5d9727e4afD42dee':
      return cheeseAbi

    case '0x679D5b2d94f454c950d683D159b87aa8eae37C9e':
      return hamsterAbi

    case '0x6d16FbB7275Ca7C4Dfdc23fb96B3B94637be88e0':
      return cakelp2

    case '0x05714FF506D066e5165397A1d77535db1F4544D6':
      // case '0x05714FF506D066e5165397A1d77535db1F4544D6':
      return panCakeLp2Abi

    case '0xAf3D7692ba17510E262207d421DA82E7f68F9756':
      return cheesePanLpAbi

    case '0x603C9D45e21bA5abe27d3AB2B3DA254A98d42C02':
      return panCheeseLpAbi

    case '0x1Af58d879d6B6BDE8a4cC8Ed23005Ad5Ad1e94DC':
      return cheeseCheeseLpAbi

    case '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16':
      return cheesePan3Abi

    case '0x494e49E603b08e10908B846E1C0D2599CaAD0605':
    // case '0x2910D21265C1E38a7fe594c9A3858537F8644CAD':
      return HamsterNFTABI

    case '0x9C64C32d7f65D5B93E044d54647Da323CaC3E3AE':
      return newStaking1

    case '0x0f3Fbe253694bf5bE6488453f8aAbda56E5727f2':
      return newStaking2

    case '0x8fF9633348F50c9C74ed70Bc5Bd2494604d6Dc41':
      return newStaking3

    // case '0x6a8f81Df5369Ae1bb4429A67FA52D95093b8CB05':
    //   return newStaking4
    case '0x6afeB7909B9f18096891c4B0dC23f81371552b14':
      return newStaking4

    case '0xB9fF900a5874418A6906b5492868B396F173E8b1':
      return newStaking5

    case '0xf8709f60e95754D38953B831151Dc0FE393CAf0f':
      return newStaking6

    // After discussion

    case '0xB87500b96bbF18CEb3E5293d445a68884f7BBf9E':
      return newStaking7

    case '0x08cC1825cDDa7d3Beeb47C7eD303B18da5BC17A3':
      return newStaking8

    case '0x6619295B65Ed1963D71d248E1E787101910CA291':
      return newStaking9

    case '0xAD57F3D0888Af45AF3D0E139315A701A1CC3DbE5':
      return newStaking10

    case '0x32b78657Ed439B94ab3F514141e16c525014e82a':
      return newStaking11

    case '0x379C878e11F9A69dA3B451d47886a14191bE0b84':
      return newStaking13

    case '0xcC87464aB98BD6da4b6E66Af471da788E01d0d32':
      return newStaking14

    case '0xDd3959dcD4418C8b0f3B00FE0f690Ff6900189C4':
      return newStaking15

    case '0x8F544b79fa4900dd06eEE3AfEdFdB556aD10B8f9':
      return newStaking16

    case '0x2593bc2dA76B1048cba0Da3D763ae8831dA928CE':
      return newStaking17

    case '0x74D29545233e629A0C119036876502b077292B07':
      return newStaking18

    case '0xF898C31845F2229c54F337C86C9656cd4978d8B2':
      return newStaking19

    case '0xE6542D7d0Ce74b49715Ad3aca2212c60926bBC45':
      return newStaking20

    case '0x68073436E90b0B8B63CEe9a4A91a3290589A7010':
      return newStaking21

    // case '0x1Af58d879d6B6BDE8a4cC8Ed23005Ad5Ad1e94DC':
    //   return newStaking24

    // case '0x1962735C32FB79b2a9A23217b63C1afc6E0be7C8':
    //   return newStaking25

    default:
      return null
  }
}
