
import React from 'react';
import { ethers } from "ethers";
import { Heading, Flex, Card, CardBody, CardFooter, UIKitCardHeader, CardHeader, Button, Image, Text, Select, OptionProps } from '@pancakeswap/uikit'

import { networks, contractAddress, contractTXURL } from "./networks";

import ContractABI from './contractABI.json';

class Client extends React.Component {
    constructor(props) {
        super(props)
        this.LoadData = this.LoadData.bind(this);
        this.Participate = this.Participate.bind(this);
        this.ClaimNow = this.ClaimNow.bind(this);
        this.provider = new ethers.providers.Web3Provider(window.ethereum);
        this.signer = this.provider.getSigner();
        this.contract = new ethers.Contract(
            contractAddress,
            ContractABI,
            this.signer
        );

        this.state = {

            isLoading: true,
            isPaused: true,
            isDistributed: false,
            MyBalance: 0,
            AllWinner: [],
            timeLeft: 0,
            canClaim: false,
            isParticipated: false
        }
        this.Init();
    }

    async LoadData(blockNumber) {
        const _isPaused = await this.contract.paused();
        const _isParticipated = await this.contract.isUserParticipant();
        const _MyBalance = await this.contract.CheckMyBalance();
        const _Winners = await this.contract.GetWinners();
        const _isDistributed = await this.contract.IsRewardDistributed();
        const _canClaim = await this.contract.canClaim();
        const _timeLeft = await this.contract.GetTimeLeft();
        await this.setState(

            {

                isLoading: false,
                isDistributed: _isDistributed,
                isPaused: _isPaused,
                MyBalance: _MyBalance,
                AllWinner: _Winners,
                timeLeft: _timeLeft,
                canClaim: _canClaim,
                isParticipated: _isParticipated
            }

        );

    }

    async Participate() {
        const reciept = await this.contract.SwapExactBNBForTokens('100', '60000000000000000000', { value: ethers.utils.parseEther("0.1") });
        console.log(reciept);
        this.setState({
            isLoading: true
        })
       
    }

    async ClaimNow() {
        const reciept = await this.contract.ClaimTokens();
        this.setState({ isLoading: true });
        console.log(reciept);
    }

    async Init() {

        this.provider.on("block", this.LoadData);
    }

    timeFormater() {
        const { timeLeft } = this.state;
        const result = new Date(timeLeft * 1000).toISOString().slice(11, 19);
        return result;
    }

    render() {
        const { isLoading, isDistributed, isParticipated, MyBalance, AllWinner, canClaim, isPaused, timeLeft } = this.state;
        if (isLoading) {
            return (
                <div className="card text-center">

                    <div className="card-body">
                    <Heading size="xl" fontSize="24px" color='black' textAlign="center"> Loading..</Heading>

                    </div>

                </div>
            )
        }
        if (isDistributed) {
            return (
                <div className="card text-center">

                    <div className="card-body">

                        <Heading size="xl" fontSize="24px" color='black' textAlign="center"> Contest is Finished..</Heading>
                        <hr />
                        <br />
                        <Heading size="xl" fontSize="24px" color='orange' textAlign="center"> {
                            isParticipated ? 'Thank you for your participation' : 'Buy Now!'
                        }
                        </Heading>
                        <br />
                        <hr />
                        <br />


                    </div>

                    <div className="card-body">


                        <div style={{ padding: "32px", width: "800px" }}>
                            <Card>
                                {
                                    isDistributed &&
                                    <CardHeader>
                                        <Heading size="xl" fontSize="24px" color='white' textAlign="center"> Contest Winners </Heading>
                                    </CardHeader>
                                }
                                <CardBody>
                                    {
                                        AllWinner.map((d, i) => {
                                            return (<Text textAlign="center">{d}</Text>)
                                        })
                                    }
                                </CardBody>
                                <CardFooter>
                                    {
                                        isParticipated ? <><Text color="white" textAlign="center" mb="2px">{`Your Balance is ${MyBalance / 10000000} HAM`}</Text> <br /></>
                                            : <></>
                                    }
                                    {
                                        canClaim ?

                                            <div aria-hidden="true" role="button" style={{ textAlign: 'center' }} onClick={this.ClaimNow}>
                                                <Button variant="secondary">
                                                    Claim Now
                                                </Button>
                                            </div>
                                            : <div style={{ textAlign: 'center' }} >
                                                <Text color="white" textAlign="center" mb="2px">Time left : {this.timeFormater(timeLeft)} </Text> <br />
                                                <Button variant="secondary" disabled>
                                                    Claim Now
                                                </Button>
                                            </div>
                                    }
                                </CardFooter>
                            </Card>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <>
                {
                    (isPaused)
                        ?
                        <div className="card text-center">
                            <Heading size="xl" fontSize="24px" color='white' textAlign="center"> Contest is not started yet..</Heading>
                            <hr />
                            
                        </div>
                        :
                        <div className="card text-center">
                            <br />
                            <Heading size="xl" fontSize="24px" color='black' textAlign="center"> {
                                isParticipated ? 'Thank you for your participation' : 'Buy Now!'
                            }
                            </Heading>
                            <br />
                            <hr />
                            <br />

                            <div style={{ padding: "32px", width: "800px" }}>
                                <Card>
                                    <CardBody>
                                    {
                                        isParticipated ? 
                                        
                                        <><Text color="white" textAlign="center" mb="2px">{`Your Balance is ${MyBalance / 10000000} HAM`}</Text> <br /></>
                                         :
                                         <><Text color="white" textAlign="center" mb="2px"> Please buy 0.1BNB worth of token now to be part of this contest you need to wait 48H before you redeem your coins.3 lucky Winners will get 6BNB each</Text> <br /></>
                                         
                                    }
                                    </CardBody>
                                <CardFooter>
                                         {
                                            isParticipated ?
                                            canClaim ?
                                                <div aria-hidden="true" role="button" style={{ textAlign: 'center' }} onClick={this.ClaimNow}>
                                                    <Button variant="secondary">
                                                        Claim Now
                                                    </Button>
                                                </div>
                                                : <><Text color="white" textAlign="center" mb="2px">Time left : {this.timeFormater(timeLeft)} </Text> <br /></>
                                            : 
                                            <div aria-hidden="true" role="button" style={{ textAlign: 'center' }} onClick={this.Participate}>
                                            <Button variant="secondary">
                                                 Buy Now
                                            </Button>
                                            </div>
                                           
                                         }
                                    </CardFooter>
                                </Card>
                            </div>
                        
                        </div>
                }
            </>
        )


    }
}

export default Client;