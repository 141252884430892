/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { readFileSync } from 'fs'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { Button, Card, CardBody, Checkbox, Heading, Flex, Input, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import './createTokenCustomStyle.css'
// import WalletConnectProvider from '@walletconnect/web3-provider'
// import { getAchievements } from '../../state/achievements/helpers';
// import Web3 from 'web3';
import BabyTokenContract from './utils/babyToken.json'

const CreateToken: React.FC = () => {
  const { t } = useTranslation()
  const [antibot, setAntibot] = useState(false)

  const TOKEN_TYPE = 'Baby Token'
  const REWARD_TOKEN = '0x679D5b2d94f454c950d683D159b87aa8eae37C9e'
  const ROUTER = 'Pancakeswap Router'
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [totalSupply, setTotalSupply] = useState('')
  const [minBalForDiv, setMinBalForDiv] = useState('')
  const [tokenRewardFee, setTokenRewardFee] = useState('')
  const [marketingFee, setMarketingFee] = useState('')
  const [autoAddLiquidity, setAutoAddLiquidity] = useState('')
  const [marketingWallet, setMarketingWallet] = useState('')
  const bscTestnet = "https://data-seed-prebsc-1-s1.binance.org:8545/"
  const [deploymentStatus, setDeploymentStatus] = useState('')
  const [disableDeploy, setDisableDeploy] = useState(false)
  const [validTotalSupplyMsg, setValidTotalSupplyMsg] = useState('')

  const { account } = useWeb3React()

  // string memory _name, string memory _symbol, uint256 _totalSupply, address _marketingAddress, uint256 _tokenRewardsFee, uint256 _liquidityFee, uint256 _marketingFee, uint256 _minimumTokenBalanceForDividends

  const checkNum = (char) => {
    return char === '0' || char === '1' || char === '2' || char === '3' || char === '4' || char === '5' || char === '6' || char === '7' || char === '8' || char === '9'
  }

  // useEffect(() => {
  //   console.log("totalSupply", totalSupply)
  // }, [totalSupply])

  useEffect(() => {
    if (Number(totalSupply) > 0) {
      setValidTotalSupplyMsg('')
    }
    if (Number(minBalForDiv) > Number(totalSupply)) {
      console.log("minBalForDiv is greater")
      setMinBalForDiv((Number(totalSupply) - 1).toString())
    }
  }, [totalSupply, minBalForDiv])




  const handleTotalSupply = (event) => {
    const result = event.target.value.replace(/\D/g, '');
    if (result !== '' && Number(result) >= 0) {
      setTotalSupply(result)
    } else {
      console.log("Please enter a valid number");

    }
    if (event.target.value === '' && totalSupply.length === 1) {
      setTotalSupply('')
    }
  }

  const handlePercentChange = (event, state, setValue) => {

    const result = event.target.value.replace(/\D/g, '');
    if (result !== '' && Number(result) <= 20 && Number(result > 0)) {
      setValue(result)
    } else {
      console.log("Please enter a valid number");
    }

    if (event.target.value === '' && state.length === 1) {
      setValue('')
    }

  }

  const handleDividendChange = (event) => {
    const result = event.target.value.replace(/\D/g, '');
    // if (result !== '' && Number(result) <= Number(totalSupply) / 1000) {
    if (result !== '' && Number(result) < Number(totalSupply)) {
      setMinBalForDiv(result)
    } else {
      console.log("Please enter a valid number");
      if (!totalSupply) {
        setValidTotalSupplyMsg("Please enter a valid total supply")
      }
    }
    if (event.target.value === '' && minBalForDiv.length === 1) {
      setMinBalForDiv('')
    }
  }



  const handleFormSubmit = (event) => {
    event.preventDefault()

    const deploy = async () => {

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        // const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/', { name: 'binance', chainId: 56 })
        console.log("provider degined here " ,provider);

        const signer = provider.getSigner();
        
        console.log("signer degined here " , signer)

        if (await (await provider.getNetwork()).chainId !== 56) {
          alert("Please Switch to BSC Mainnet.")
          setDisableDeploy(false)
          setDeploymentStatus("Please Switch to BSC Mainnet")
          return
        }

        // if (await signer.getAddress() === marketingWallet) {
        if (account === marketingWallet) {
          setDisableDeploy(false)
          setDeploymentStatus("Marketing wallet can't be same as owner's wallet.")
          return
        }

        if (Number(tokenRewardFee) + Number(autoAddLiquidity) + Number(marketingFee) > 20) {
          setDisableDeploy(false)
          setDeploymentStatus("Reward fee + Liquidity fee + Marketing fee must be less than 20%.")
          return
        }

        const factory = new ethers.ContractFactory(BabyTokenContract.abi, BabyTokenContract.bytecode, signer)

        setDeploymentStatus("Deploying Contract ....")

        const totalSup = new BigNumber(totalSupply).shiftedBy(18).toString()
        const minDivBal = new BigNumber(minBalForDiv).shiftedBy(18).toString()

        // console.log("tokenReward fee", (Number(tokenRewardFee) + Number(autoAddLiquidity) + Number(marketingFee)) * 100)

        const contract = await factory.deploy(tokenName, tokenSymbol, totalSup, marketingWallet, Number(tokenRewardFee) * 100, Number(autoAddLiquidity) * 100, Number(marketingFee) * 100, minDivBal)

        await contract.deployed()
        setDisableDeploy(false)
        setDeploymentStatus(`Contract Deployed To: ${contract.address}`)


      } catch (error) {
        setDeploymentStatus("Error occurred while deploying contract. ")
        setDisableDeploy(false)
        console.log(error)

      }

    }

    deploy()

  }







  return (
    <>
      <Page>
        <PageHeader className='createToken__header' >
          <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']} className='createToken__header-container' >
            <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
              <Heading as="h1" scale="xxl" color="textSubtle" mb="24px" className='createToken__header-heading' >
                {t('Create Token')}
              </Heading>
              <Heading scale="lg" color="textSubtle" className='createToken__header-subHeading' >
                {t(`User can create any BabyToken according to their requirements here !`)}
              </Heading>

            </Flex>
          </Flex>
        </PageHeader>

        <Card mt="30px" >

          <form action="" onSubmit={(e) => handleFormSubmit(e)} >
            <CardBody className='createToken__formContainer' >

              <Flex flexDirection="column" justifyContent="center" mb="20px">
                <Text className='createToken__formFiledHeading' >{t('Token type')}</Text>
                <select className='createToken__formSelectTab'>
                  <option value="Baby Token">Baby Token</option>
                </select>
              </Flex>


              <Flex flexDirection="column" justifyContent="center" mb="20px">
                <Text className='createToken__formFiledHeading' >{t('Name')}</Text>
                <Input className='createToken__formFiledInput' placeholder="Ex: Binance" onChange={(e) => setTokenName(e.target.value)} required />
              </Flex>

              <Flex flexDirection="column" justifyContent="center" mb="20px">
                <Text className='createToken__formFiledHeading' >{t('Token symbol')}</Text>
                <Input className='createToken__formFiledInput' placeholder="Ex: BNB" onChange={(e) => setTokenSymbol(e.target.value)} required />
              </Flex>


              <Flex flexDirection="column" justifyContent="center" mb="20px">
                <Text className='createToken__formFiledHeading' >{t('Total supply')}</Text>
                <Input
                  className='createToken__formFiledInput'
                  placeholder="Ex: 100000000000"
                  type='text'
                  onChange={handleTotalSupply}
                  required
                  value={totalSupply}
                />
              </Flex>

              <Flex flexDirection="column" justifyContent="center" mb="20px">
                <Text className='createToken__formFiledHeading' >{t('Router')}</Text>
                <Input className='createToken__formFiledInput' placeholder="0x..." value={ROUTER} disabled />
              </Flex>


              <Flex flexDirection="row" justifyContent="space-between" mb="20px" width="100%" className='createToken__doubleFormFiledContainer' >

                <Flex flexDirection="column" justifyContent="center" width="49%">
                  <Text className='createToken__formFiledHeading' >{t('Reward token')}</Text>
                  <Input className='createToken__formFiledInput' placeholder="0x..." value={REWARD_TOKEN} disabled />
                </Flex>
                <Flex flexDirection="column" justifyContent="center" width="49%">
                  <Text className='createToken__formFiledHeading'>{t(`Minimum token balance for dividends`)} {totalSupply ? `Max (${Number(totalSupply) - 1})` : ''} </Text>
                  <Input
                    placeholder="Ex: 1000000000"
                    className='createToken__formFiledInput'
                    required
                    type='text'
                    onChange={handleDividendChange}
                    value={minBalForDiv}

                  />

                  {
                    validTotalSupplyMsg ? <p> {validTotalSupplyMsg} </p> : <></>
                  }

                </Flex>

              </Flex>

              <Flex flexDirection="row" justifyContent="space-between" mb="20px" width="100%" className='createToken__doubleFormFiledContainer' >
                <Flex flexDirection="column" justifyContent="center" width="49%">
                  <Text className='createToken__formFiledHeading' >{t('Token reward fee (%)')}</Text>
                  <Input
                    type="text"
                    placeholder="1 - 20"
                    className='createToken__formFiledInput'
                    required
                    onChange={(event) => handlePercentChange(event, tokenRewardFee, setTokenRewardFee)}
                    value={tokenRewardFee}
                  />
                </Flex>
                <Flex flexDirection="column" justifyContent="center" width="49%">
                  <Text className='createToken__formFiledHeading' >{t('Liquidity fee (%)')}</Text>
                  <Input
                    type="text"
                    placeholder="1 - 20"
                    className='createToken__formFiledInput'
                    required
                    onChange={(event) => handlePercentChange(event, autoAddLiquidity, setAutoAddLiquidity)}
                    value={autoAddLiquidity}
                  />
                </Flex>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between" mb="20px" width="100%" className='createToken__doubleFormFiledContainer' >
                <Flex flexDirection="column" justifyContent="center" width="49%">
                  <Text className='createToken__formFiledHeading' >{t('Marketing fee (%)')}</Text>
                  <Input
                    type="text"
                    placeholder="1 - 20"
                    className='createToken__formFiledInput'
                    required
                    onChange={(event) => handlePercentChange(event, marketingFee, setMarketingFee)}
                    value={marketingFee}

                  />
                </Flex>
                <Flex flexDirection="column" justifyContent="center" width="49%">
                  <Text className='createToken__formFiledHeading' >{t('Marketing wallet')}</Text>
                  <Input placeholder="Ex: 0x..." className='createToken__formFiledInput' required onChange={(e) => setMarketingWallet(e.target.value)} />
                </Flex>
              </Flex>


              {/* <Flex flexDirection="row" justifyContent="start" mb="20px" width="100%" alignItems="center" alignContent="center">
                <Checkbox
                  name="confirmed"
                  type="checkbox"
                  checked={antibot}
                  onChange={() => setAntibot(!antibot)}
                  scale="sm"
                  className='antiBot__checkBox'
                />
                <Text ml="10px" style={{ userSelect: 'none' }} className='antibotCheckText' >
                  {t('Implement Anti-Bot System?')}
                </Text>
              </Flex> */}


              <Flex justifyContent="center" width="100%" mt="30px">
                {/* <Button className='createToken__createTokenButton' disabled type='submit' >{t('Create Token')}</Button> */}
                <button className='createToken__createTokenButton' disabled={disableDeploy || !account} type='submit' >{t('Create Token')}</button>

              </Flex>

              <div className='createToken__messageContainer' >
                {
                  deploymentStatus ? <p>{deploymentStatus}</p> : <></>
                }
              </div>


            </CardBody>
          </form>

        </Card>

        <div className='bottom_info'>
          <div className='bottom_info_img'><img src="./images/icon_main.png" alt="" /></div>
          <h1>Certik Recommendation For Centralization Risks</h1>
          <div className='addonInfo'>
            <div className='width_100'>
              <h1>Recommendation</h1>
              <p>The risk describes the current project design and potentially makes iterations to improve in the security
                operation and level of decentralization, which in most cases cannot be resolved entirely at the present
                stage. We advise the client to carefully manage the privileged account's private key to avoid any potential
                risks of being hacked. In general, we strongly recommend centralized privileges or roles in the protocol be
                improved via a decentralized mechanism or smart-contract-based accounts with enhanced security
                practices, e.g., multisignature wallets. Indicatively, here are some feasible suggestions that would also
                mitigate the potential risk at a different level in terms of short-term, long-term and permanent.</p>
            </div>
            <div className='width_100 left_0_position'>
              <h1>Short Term</h1>
              <p>Timelock and Multi sign (⅔, ⅗) combination mitigate by delaying the sensitive operation and avoiding a
                  single point of key management failure.
                  <ul>
                    <li>Time-lock with reasonable latency, e.g., 48 hours, for awareness on privileged operations  <span> AND</span></li>
                    <li>
                      Assignment of privileged roles to multi-signature wallets to prevent a single point of failure due to the
                      private key compromised
                      <span> AND</span></li>
                    <li>
                      A medium/blog link for sharing the timelock contract and multi-signers addresses information with the
                      public audience.
                    </li>
                  </ul>
              </p>
            </div>
            <div className='width_100'>
              <h1>Long Term</h1>
              <p>Timelock and DAO, the combination, mitigate by applying decentralization and transparency.
                  <ul>
                    <li>Time-lock with reasonable latency, e.g., 48 hours, for awareness on privileged operations  <span> AND</span></li>
                    <li>
                      Introduction of a DAO/governance/voting module to increase transparency and user involvement.
                      <span> AND</span></li>
                    <li>
                      A medium/blog link for sharing the timelock contract, multi-signers addresses, and DAO information
                      with the public audience.
                    </li>
                  </ul>
              </p>
            </div>
            <div className='width_100 left_0_position'>
              <h1>Permanent</h1>
              <p>Renouncing the ownership or removing the function can be considered fully resolved.
                  <ul>
                    <li>Renounce the ownership and never claim back the privileged roles.</li>
                  </ul>
              </p>
            </div>
          </div>

          <div className='bottom_info_img'><img src="./images/icon_main.png" alt="" /></div>

          <h1>Certik Recommendation For Initial Token Distribution</h1>
          <div className='addonInfo flex_row'>
            <div className='width_100'>
              <h1>Recommendation</h1>
              <p>We recommend the team be transparent regarding the initial token distribution process, and the team shall
                make enough efforts to restrict the access of the private key. The risk describes the current project design
                and potentially makes iterations to improve in the security operation and level of decentralization, which in
                most cases cannot be resolved entirely at the present stage. We advise the client to be transparent
                regarding the initial token distribution process and carefully manage the privileged account's private key to
                avoid any potential risks of being hacked. In general, we strongly recommend centralized privileges or roles
                in the protocol be improved via a decentralized mechanism or smart-contract-based accounts with
                enhanced security practices, e.g., multisignature wallets.
                <br />
                Indicatively, here are some feasible suggestions that would also mitigate the potential risk at a different level
                in terms of short-term, long-term and permanent.
                </p>
            </div>
            <div className='width_100'>
              <h1>Short Term</h1>
              <p>Timelock and Multi sign (⅔, ⅗) combination mitigate by delaying the sensitive operation and avoiding a
                  single point of key management failure.
                  <ul>
                    <li>Time-lock with reasonable latency, e.g., 48 hours, for awareness on privileged operations  <span> AND</span></li>
                    <li>
                    Assignment of privileged roles to multi-signature wallets to prevent a single point of failure due to the
                    private key compromised
                      <span> AND</span></li>
                    <li>
                    A medium/blog link for sharing the timelock contract and multi-signers addresses information with the
public audience.
                    </li>
                  </ul>
              </p>
            </div>
            <div className='width_100'>
              <h1>Long Term</h1>
              <p>Timelock and DAO, the combination, mitigate by applying decentralization and transparency.
                  <ul>
                    <li>Time-lock with reasonable latency, e.g., 48 hours, for awareness on privileged operations  <span> AND</span></li>
                    <li>
                      Introduction of a DAO/governance/voting module to increase transparency and user involvement.
                      <span> AND</span></li>
                    <li>
                      A medium/blog link for sharing the timelock contract, multi-signers addresses, and DAO information
                      with the public audience.
                    </li>
                  </ul>
              </p>
            </div>
          </div>
        </div>
      </Page>
    </>
  )
}

export default CreateToken
