import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import cheeseLpAbi from 'config/abi/cheeselp.json'
import multicall from 'utils/multicall'
import { getMasterchefContract, getCheeseLpContract } from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import { simpleRpcProvider } from 'utils/providers'
import BigNumber from 'bignumber.js'
import cheeseAbi from 'config/abi/cheese.json'
import { getContractAbi } from 'utils/getAbi'
import addresses from '../../config/constants/contracts'
// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB')
const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB')
const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)
const masterChefContract = getMasterchefContract()

export const fetchPoolsAllowance = async (account) => {

// console.log("nonBnbPools =>",nonBnbPools)

  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress)],
  }))
  // console.log('calls =>', calls)
  const allowances = await multicall(erc20ABI, calls)
  
  return nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

// added by me

// const dataToCall = [
//   { address: '0x08Aed8578dAaBf12d48031fA5d9727e4afD42dee', name: 'totalSupply', abi: cheeseAbi },
//   { address: '0x71EDa46Dc456561F6b1a498646B8eA58bB89CFf6', name: 'poolFee', abi: cheeseLpAbi },
// ]

export const getDataFromContract = async (dataToCallObj) => {
  const call = [
    {
      address: dataToCallObj.address,
      name: dataToCallObj.name,
      params: dataToCallObj.params,
    },
  ]

  
  // console.log('call', call)
  // console.log('dataToCallObj.abi =>', dataToCallObj.abi)
  const result = await multicall(dataToCallObj.abi, call)
  // console.log("result",dataToCallObj.address,result)
  return result
}



export const checkAllowance = async (accountAddress) => {
  const call = [
    {
      address: addresses.cheeselp[56],
      name: 'allowance',
      params: [accountAddress, addresses.hams[56]],
    },
  ]
  const result = await multicall(cheeseAbi, call)
  return result
}

// export const approve = async (approveDataObj) => {
//   const { stakingContractAddress, rewardToken, stackedToken, amount, walletAddress } = approveDataObj
//   const call = [{ address: stackedToken, name: 'approve', params: [rewardToken, amount] }]
//   const contractAbi = getContractAbi(stackedToken)
//   const result = await multicall(contractAbi, call)
//   console.log('result', result)
// }

export const fetchUserBalances = async (account) => {
  // Non BNB pools

  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(erc20ABI, calls)
  const tokenBalances = nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )

  // BNB pools
  const bnbBalance = await simpleRpcProvider.getBalance(account)
  const bnbBalances = bnbPools.reduce(
    (acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(bnbBalance.toString()).toJSON() }),
    {},
  )

  return { ...tokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (account) => {
  const calls = nonMasterPools.map((p) => {
    return {
    
      address: getAddress(p.contractAddress),
      name: 'userInfo',
      params: [account],
    }
  })
  
  const userInfo = await multicall(sousChefABI, calls)
  const stakedBalances = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  // Cake / Cake pool
  const { amount: masterPoolAmount } = await masterChefContract.userInfo('0', account)

  return { ...stakedBalances, 0: new BigNumber(masterPoolAmount.toString()).toJSON() }
}

export const fetchUserPendingRewards = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(sousChefABI, calls)
  const pendingRewards = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  // Cake / Cake pool
  const pendingReward = await masterChefContract.pendingCheese('0', account)

  return { ...pendingRewards, 0: new BigNumber(pendingReward.toString()).toJSON() }
}
