export default {
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0xbe20cc5d3B886e9f7aD70F94D36C66204Dd943ff',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  presale: {
    56: '0x5D40763d83942a5cfa56A40667014E9748B0e785',
    97: '',
  },
  // cheeseStake: {
  //   56: '0x71EDa46Dc456561F6b1a498646B8eA58bB89CFf6',
  //   97: '',
  // },
  cheeselp: {
    56: '0x08Aed8578dAaBf12d48031fA5d9727e4afD42dee',
    97: '',
  },
  hams: {
    56: '0x679D5b2d94f454c950d683D159b87aa8eae37C9e',
    97: '',
  },

  hamcheeselp: {
    56: '0x71EDa46Dc456561F6b1a498646B8eA58bB89CFf6',
    97: '',
  },
  pancheeselp: {
    56: '0x09DB126aFdE136f94B02c0A7A7D9De4C7A3F97D5',
    97: '',
  },
  pancheeselp2: {
    56: '0xF90423A390483e5EFB14b966a011019114E6BcCA',
    97: '',
  },
  pancakelp: {
    56: '0x603C9D45e21bA5abe27d3AB2B3DA254A98d42C02',
    97: '',
  },
  cakelp2: {
    56: '0x6d16FbB7275Ca7C4Dfdc23fb96B3B94637be88e0',
    97: '',
  },
  cheesePanLp: {
    56: '0xAf3D7692ba17510E262207d421DA82E7f68F9756',
    97: '',
  },
  cheeseCheeseLp: {
    56: '0x1Af58d879d6B6BDE8a4cC8Ed23005Ad5Ad1e94DC',
    97: '',
  },
  cheeseCheeseLp2: {
    56: '0x379c878e11f9a69da3b451d47886a14191be0b84',
    97: '',
  },
  cheese: {
    // testnet
    56: '',
    97: '0xD477bF2d39D7EB5eF3d00c666CA34bE46E70A796',
  },
  // HamBab Integration
  hambabToken: {
    56: '0x1e97A3789DCfcf1309f24A7dD4FaB27Fa16e4B74',
    97: '0x19C74B70EDC022b3653D16b3d0449ce8d73d999E',
  },
  hambabStaking: {
    56: '0xe59356ea1cB52140B0e5862FA0bfEe2D6e086cCd',
    97: '0xa6ca5087356e5B2316DF9acb4C1b5b220Df545B9',
  },
  autoBurn: {
    56: '0xB5Bf70fdBB7afbFdA43C5C3beABA9B79D1713CB1',
    97: '0x9a71f3c22011A6b544A986A9310BD5615e9DF55F',
  },
}
