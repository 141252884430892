/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { FaInstagram, FaTelegramPlane } from 'react-icons/fa'
import { AiOutlineTwitter } from 'react-icons/ai'
import { useTranslation } from 'contexts/Localization'
import { footerLinks } from './Menu/config/footerConfig'


const Footer = () => {

    const { currentLanguage, setLanguage, t } = useTranslation()

    return (
        <footer className='footerContainer' >
            <div className='footerImageWaveContainer' >
                <img src="images/footer-img.png" alt="" />
            </div>
            <div className="footerLogoContainer" >
                <img src="images/tokens/hamsterSwapLogo.png" alt="" />
                <span className='footerLogoTextContainer' > <span>Hamster</span> <span>Swap</span> </span>
            </div>

            <div className='footerLinksContainer' >
                {footerLinks(t).map((linkObj) => {
                    return <FooterColumn key={linkObj.label} data={linkObj} />
                })}
            </div>

            <div className='footerLinksBottomWrapper' >
                <div className='footerLinksBottomContainer' >

                    <p>
                        © Copyright 2022. All Rights Reserved
                    </p>

                    <div className='bottomLinksIconContainer' >
                        <a href='https://www.instagram.com/hamster_coin/' target="_blank" rel="noreferrer" ><FaInstagram className='footerIcons' /></a>
                        <a href='https://twitter.com/_hamster_coin' target="_blank" rel="noreferrer" ><AiOutlineTwitter className='footerIcons' /></a>
                        <a href='https://t.me/Cheese_Token' target="_blank" rel="noreferrer" ><FaTelegramPlane className='footerIcons' /></a>
                    </div>

                </div>
            </div>

        </footer>
    )
}

const FooterColumn = ({ data }) => {

    return <div className='footerColumn' >

        <h1>{data.label}</h1>
        <img src="images/custom_icon_dark.png" alt="" />

        <div className='footerColumns__linksContainer' >

            {data.items.map((itemData) => {
                return <a key={`${itemData.label}`} href={itemData.href}>{itemData.label}</a>
            })}

        </div>


    </div>
}

export default Footer