import React from 'react'
import { ethers } from "ethers";
import { Heading, Flex, Card, CardBody, CardFooter, CardHeader, Button, Image, Text, Select, OptionProps } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import { networks, contractAddress, contractTXURL } from "./networks";
import Admin from './Admin'
import Client from './Client'
import ContractABI from './contractABI.json';


export default class ContestLogin extends React.Component {

    constructor() {
        super();

        this.connectToWallet = this.connectToWallet.bind(this);
        this.setAccount = this.setAccount.bind(this);

        this.state = {
            account: "",
            walletConnected: false,
            owner: ""
        }
        this.requestNetwork();
    }

    async setAccount(NewAccount) {
        this.setState({
            account: NewAccount[0],
        })
    }



    rules() {
        const { walletConnected } = this.state;

        return (
            <>
                {
                    (!walletConnected) &&

                    <div className='mt-2'>
                        <br /><br /><br /><br />
                        <div className="alert alert-success" role="alert">

                            <Text color='orange' fontSize='24px' > Contest Rules!</Text>
                            <br /><br />
                            <Text color='black'> Please read all the Rules care fully before you participate</Text>

                            <hr />
                            <Text color='black'> 1. Connect you wallet with BSC using Metamask</Text>

                            <hr />
                            <Text color='black'> 2. Click buy button it will buy 0.1BNB worth of HAM token from pancake </Text>

                            <hr />
                            <Text color='black'> 3. Each user can have only 1 entry</Text>

                            <hr />
                            <Text color='black'> 4. You can redeem your token only after 48H after buying</Text>

                            <hr />
                            <Text color='black'> 5. Three lucky winners will be selected from participants each will get 6BNB</Text>

                        </div>
                    </div>
                }
            </>
        )

    }

    isWalletConnected() {
        const { walletConnected, account } = this.state


        return (
            <>
                {
                    (walletConnected)
                        ?
                        <div>
                            <Text color="brown">{account} </Text>  <span>  &nbsp;  </span>

                        </div>
                        :
                        <div>
                            <div aria-hidden="true" role="button" className="btn btn-warning my-2 my-sm-0" onClick={this.connectToWallet}>Connect to Wallet</div>
                        </div>
                }
            </>

        )
    }



    async connectToWallet() {
        try {
            if (window.ethereum.chainId === `0x${Number(56).toString(16)}`) {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(
                    contractAddress,
                    ContractABI,
                    signer
                );
                console.log(accounts[0]);
                const ownerr = await contract.owner()

                this.setState({
                    account: accounts[0],
                    walletConnected: true,
                    owner: ownerr
                })

                window.ethereum.on('accountsChanged', this.setAccount);
            } else {
                alert("Please Switch your wallet to BSC Testnet")
            }
        } catch (err) {
            console.log(err);
        }
    }

    async requestNetwork() {
        const { account } = this.state;
        try {
            if (!window.ethereum) throw new Error("No crypto wallet found");
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        chainId: `0x${Number(56).toString(16)}`

                    }
                ]
            });
        } catch (err) {
            console.log(err)
        }
    }


    render() {
        const { name, walletConnected, account, owner } = this.state;

        return (
            <div>
                <Page>
                    <PageHeader>
                        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
                            <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
                                <Heading as="h1" scale="xxl" color="brown" mb="24px">
                                    Hamster Contest
                                </Heading>

                                <Card mt="30px" >
                                    <CardBody className='createToken__formContainer' >

                                        <Flex flexDirection="column" justifyContent="center" mb="20px">
                                            <Flex flexDirection="row" justifyContent="center" mt="20px" flex={2}>
                                                <Text> {this.isWalletConnected()}</Text>
                                            </Flex>
                                            <Flex flexDirection="row" justifyContent="center" mt="20px">
                                                {
                                                    (walletConnected)
                                                        ? (account.toLowerCase() === owner.toLowerCase()) ? <Admin /> : <Client />
                                                        : this.rules()
                                                }
                                            </Flex>
                                        </Flex>
                                    </CardBody>
                                </Card>

                            </Flex>
                        </Flex>
                    </PageHeader>
                </Page>
            </div>
        )
    }
}