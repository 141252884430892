import React from 'react'
import { Button } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const DisconnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { logout } = useAuth()

  return (
    <button type='button' className='disconnectWalletButton' onClick={() => { logout() }} {...props}>
      {t('Disconnect')}
    </button>
  )
}

const DisconnectWalletDarkButton = (props) => {
  const { t } = useTranslation()
  const { logout } = useAuth()

  return <button type='button' className='disconnectWalletDarkButton' onClick={() => { logout() }} {...props}>
    {t('Disconnect')}
  </button>

}

export { DisconnectWalletDarkButton }
export default DisconnectWalletButton
