import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'CHEESE',
  //   lpAddresses: {
  //     97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
  //     56: '0xfB01CE6b28597daCc703A385865D4f637e104297',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.cake,

  //   isTokenOnly: true,
  // },
  {
    pid: 0,
    lpSymbol: 'CHEESE',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0x08Aed8578dAaBf12d48031fA5d9727e4afD42dee',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.cake,

    // isTokenOnly: true,
  },
  {
    pid: 1,
    lpSymbol: 'CHEESE-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x3714c3dC40afe192bB12Ab1BECc9b9416a7c6280',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,

    isMonthlyReward: false,
    isPid: false,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,

    isMonthlyReward: false,
    isPid: false,
  },
  // {
  //   pid: 3,
  //   lpSymbol: 'Hamster',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x679D5b2d94f454c950d683D159b87aa8eae37C9e',
  //   },
  //   token: serializedTokens.hamster,
  //   quoteToken: serializedTokens.cake,
  //   isTokenOnly: true,
  //   isPid:false
  // },
  {
    pid: 4,
    lpSymbol: 'HAM-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x99bF9cC0154655C5e913b752984A92Ad391aFE32',
    },
    token: serializedTokens.hamster,
    quoteToken: serializedTokens.cake,

    isMonthlyReward: false,
    isPid: false,
  },

  // added by me

  // {
  //   pid: 6,
  //   lpSymbol: 'Hamster',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x09DB126aFdE136f94B02c0A7A7D9De4C7A3F97D5',
  //   },
  //   token: serializedTokens.hamster,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },

  // {
  //   pid: 7,
  //   lpSymbol: 'HAM-CHEESE LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xF90423A390483e5EFB14b966a011019114E6BcCA',
  //   },
  //   token: serializedTokens.pancakelp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },

  // {
  //   pid: 8,
  //   lpSymbol: 'HAM-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x603C9D45e21bA5abe27d3AB2B3DA254A98d42C02',
  //   },
  //   token: serializedTokens.cakelp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },

  // {
  //   pid: 10,
  //   lpSymbol: 'BUSD-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xAf3D7692ba17510E262207d421DA82E7f68F9756',
  //   },
  //   token: serializedTokens.cakelp3,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },

  {
    pid: 11,
    lpSymbol: 'CHEESE',
    lpAddresses: {
      97: '',
      56: '0xcC87464aB98BD6da4b6E66Af471da788E01d0d32', 
      // 56: '0x1Af58d879d6B6BDE8a4cC8Ed23005Ad5Ad1e94DC', // Older
    },
    token: serializedTokens.cheeselp,
    quoteToken: serializedTokens.cheeselp,
    isTokenOnly: true,
    isMonthlyReward: false,
    isPid: false,
    package: 0,
    minStakingPerWallet: 1000000000000000000000,
    maxStakingPerWallet: 0,
  },
  

  // // different contracts

  // {
  //   pid: 12,
  //   lpSymbol: 'CHEESE-CHEESE-2',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x379c878e11f9a69da3b451d47886a14191be0b84',
  //   },
  //   token: serializedTokens.cheeselp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,
  //   isMonthlyReward: true,
  //   monthlyRewardToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   isPid: true,
  //   package: 6,
  //   minStakingPerWallet: null,
  //   maxStakingPerWallet: 150000000000,
  // },
  // {
  //   pid: 13,
  //   lpSymbol: 'HAM-CHEESE LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x71EDa46Dc456561F6b1a498646B8eA58bB89CFf6',
  //   },
  //   token: serializedTokens.hamster,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,
  //   isMonthlyReward: true,
  //   monthlyRewardToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   isPid: true,
  //   package:2,
  //   minStakingPerWallet:null,
  //   maxStakingPerWallet:null,
  // },

  // New Staking Contract Added On - 22 July, 2022

  {
    pid: 14,
    lpSymbol: 'CHEESE-BNB',
    lpAddresses: {
      97: '',
      // 56: '0x9C64C32d7f65D5B93E044d54647Da323CaC3E3AE', // older
      56: '0x8F544b79fa4900dd06eEE3AfEdFdB556aD10B8f9',
    },
    token: serializedTokens.cakelp2,
    quoteToken: serializedTokens.cheeselp,
    isTokenOnly: true,

    isMonthlyReward: false,
    isPid: false,
  },

 

  // {
  //   pid: 15,
  //   lpSymbol: 'CHEESE-HAM',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0f3Fbe253694bf5bE6488453f8aAbda56E5727f2',
  //   },
  //   token: serializedTokens.pancakelp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },
  // {
  //   pid: 16,
  //   lpSymbol: 'BUSD-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x8fF9633348F50c9C74ed70Bc5Bd2494604d6Dc41',
  //   },
  //   token: serializedTokens.cakelp3,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },
  // {
  //   pid: 17,
  //   lpSymbol: 'HAM-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xDd3959dcD4418C8b0f3B00FE0f690Ff6900189C4',
  //     // 56: '0x6afeB7909B9f18096891c4B0dC23f81371552b14',
  //   },
  //   token: serializedTokens.cakelp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,
  //   isMonthlyReward: false,
  //   isPid: false,
  // },

  // {
  //   pid: 20,
  //   lpSymbol: 'CHEESE-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x8F544b79fa4900dd06eEE3AfEdFdB556aD10B8f9',
  //     // 56: '0xB87500b96bbF18CEb3E5293d445a68884f7BBf9E',
  //   },
  //   token: serializedTokens.cakelp2,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,
  //   isMonthlyReward: false,
  //   isPid: false,
  // },
  {
    pid: 21,
    lpSymbol: 'BUSD-BNB',
    lpAddresses: {
      97: '',
      56: '0x2593bc2dA76B1048cba0Da3D763ae8831dA928CE',
      // 56: '0x08cC1825cDDa7d3Beeb47C7eD303B18da5BC17A3',
    },
    token: serializedTokens.cakelp3,
    quoteToken: serializedTokens.cheeselp,
    isTokenOnly: true,
    isMonthlyReward: false,
    isPid: false,
  },
  // {
  //   pid: 22,
  //   lpSymbol: 'HAM-CHEESE',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x74D29545233e629A0C119036876502b077292B07',
  //     // 56: '0x6619295B65Ed1963D71d248E1E787101910CA291',
  //   },
  //   token: serializedTokens.pancakelp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,
  //   isMonthlyReward: false,
  //   isPid: false,
  // },
  // {
  //   pid: 18,
  //   lpSymbol: 'HAM',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xF898C31845F2229c54F337C86C9656cd4978d8B2',
  //     // 56: '0x32b78657Ed439B94ab3F514141e16c525014e82a',
  //   },
  //   token: serializedTokens.hamster,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },

  // Long term contracts
  {
    pid: 19,
    lpSymbol: 'HAM(Long-Term)',
    lpAddresses: {
      97: '',
      56: '0xE6542D7d0Ce74b49715Ad3aca2212c60926bBC45',
      // 56: '0xAD57F3D0888Af45AF3D0E139315A701A1CC3DbE5',
    },
    token: serializedTokens.hamster,
    quoteToken: serializedTokens.cheeselp,
    isTokenOnly: true,
    isMonthlyReward: true,
    monthlyRewardToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    isPid: true,
    package: 2,
    minStakingPerWallet: null,
    maxStakingPerWallet: null,
  },

  // {
  //   pid: 23,
  //   lpSymbol: 'CHEESE(Long-Term)',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x68073436E90b0B8B63CEe9a4A91a3290589A7010',
  //     // 56: '0x379C878e11F9A69dA3B451d47886a14191bE0b84',
  //   },
  //   token: serializedTokens.cheeselp,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,
  //   isMonthlyReward: true,
  //   monthlyRewardToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   isPid: true,
  //   package: 6,
  //   minStakingPerWallet: null,
  //   maxStakingPerWallet: 150000000000,
  // },

  {
    pid: 24,
    lpSymbol: 'CHEESE (OLD)',
    lpAddresses: {
      97: '',
      // 56: '0xcC87464aB98BD6da4b6E66Af471da788E01d0d32', 
      56: '0x1Af58d879d6B6BDE8a4cC8Ed23005Ad5Ad1e94DC',
    },
    token: serializedTokens.cheeselp,
    quoteToken: serializedTokens.cheeselp,
    isTokenOnly: true,
    isMonthlyReward: false,
    isPid: false,
    package: 0,
    minStakingPerWallet: 1000000000000000000000,
    maxStakingPerWallet: 0,
  },

  // {
  //   pid: 25,
  //   lpSymbol: 'CHEESE-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1962735C32FB79b2a9A23217b63C1afc6E0be7C8',
  //   },
  //   token: serializedTokens.cakelp2,
  //   quoteToken: serializedTokens.cheeselp,
  //   isTokenOnly: true,

  //   isMonthlyReward: false,
  //   isPid: false,
  // },
]

export default farms

// TESTNET

// {
//   pid:13,
//   lpSymbol:'CHEESE',
//   lpAddresses: {
//     97: '0xBF5841C23585d43A0a1bb6718227b607BD46C9Ea',
//     56: '',
//   },
//   token: serializedTokens.cheese,
//   quoteToken: serializedTokens.cheese,
//   isTokenOnly: true,
// }
