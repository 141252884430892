import React from 'react'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'



const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)


  return (
    <button type='button' className='connectWalletButton' onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet ')}
    </button>
  )
}

const ConnectWalletStretchedButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <button type='button' className='connectWalletStretchedButton' onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet')}
    </button>
  )
}

const ConnectWalletDarkButton = (props) => {

  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)


  return <button type='button' className='connectWalletDarkButton' onClick={onPresentConnectModal} {...props}>
    {t('Connect Wallet ')}
  </button>

}

const ConnectWalletOutlinedButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)
  
  return <button type='button' className='connectWalletOutlinedButton' onClick={onPresentConnectModal} {...props}>
    {t('Connect Wallet')}
  </button>

}

export default ConnectWalletButton
export { ConnectWalletDarkButton, ConnectWalletStretchedButton, ConnectWalletOutlinedButton }