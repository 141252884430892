import React, { lazy, useEffect } from 'react'
// import Web3 from 'web3'
import Web3Modals from 'web3modal'
import { AbiItem } from 'web3-utils'
import WalletConnectProviders from '@walletconnect/web3-provider'
import Fortmatics from 'fortmatic'
import evmChainss from 'evm-chains'
import swal from 'sweetalert'
import Navbar from 'components/Navbar'
import MenuMobile from 'components/NavbarMobile'
import Footer from 'components/Footer'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import useUserAgent from 'hooks/useUserAgent'
import useScrollOnRouteChange from 'hooks/useScrollOnRouteChange'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import GlobalCheckClaimStatus from './components/GlobalCheckClaimStatus'
import history from './routerHistory'
// Views included in the main bundle
import Swap from './views/Swap'
import ContestLogin from './views/Contest/ContestLogin'
// import Launch from './views/Launch'
import CreateToken from './views/CreateToken'

import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'

import PhishingWarningBanner from './components/PhishingWarningBanner'
import './style/customGlobalStyles.css'
import networkConfig from './utils/config.json'

const Web3Modal = Web3Modals
const WalletConnectProvider = WalletConnectProviders
const Fortmatic = Fortmatics
const evmChains = evmChainss

// const networkConfig = './utils/config.json'
let web3Modal
let provider

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const NotFound = lazy(() => import('./views/NotFound'))
const ComingSoon = lazy(() => import('./views/ComingSoon'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const NFT = lazy(() => import('./views/NFTMinted/index'))
const Bab = lazy(() => import('./views/Bab'))
// const FarmAuction = lazy(() => import('./views/FarmAuction'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Ifos = lazy(() => import('./views/Ifos'))
// const Teams = lazy(() => import('./views/Teams'))
// const Team = lazy(() => import('./views/Teams/Team'))
// const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
// const Predictions = lazy(() => import('./views/Predictions'))
// const PredictionsLeaderboard = lazy(() => import('./views/Predictions/Leaderboard'))
// const Voting = lazy(() => import('./views/Voting'))
// const Proposal = lazy(() => import('./views/Voting/Proposal'))
// const CreateProposal = lazy(() => import('./views/Voting/CreateProposal'))
// const Info = lazy(() => import('./views/Info'))
const NftMarket = lazy(() => import('./views/Nft/market'))
// const ProfileCreation = lazy(() => import('./views/ProfileCreation'))
// const PancakeSquad = lazy(() => import('./views/PancakeSquad'))
// This config is required for number formatting

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account } = useWeb3React()

  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()
  useScrollOnRouteChange()
  useUserAgent()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={[]} />
      {/* <Menu > */}
      {/* <PhishingWarningBanner /> */}
      <Navbar />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/contest" exact>
           <ContestLogin/>
          </Route>
          {/* <Route exact path="/farms/auction">
              <FarmAuction />
            </Route> */}

          {/* closed */}
          <Route path="/farms">
            <Farms />
          </Route>
          <Route path="/createToken">
            <CreateToken />
          </Route>

          {/* 

          <Route path="/pools">
            <Pools />
          </Route> */}

          {/*
            <Route path="/lottery">
              <Lottery />
            </Route>
            <Route path="/ifo">
              <Ifos />
            </Route>
            <Route exact path="/teams">
              <Teams />
            </Route>
            <Route path="/teams/:id">
              <Team />
            </Route>
            <Route path="/create-profile">
              <ProfileCreation />
            </Route>
            <Route path="/competition">
              <TradingCompetition />
            </Route>
            <Route exact path="/prediction">
              <Predictions />
            </Route>
            <Route path="/prediction/leaderboard">
              <PredictionsLeaderboard />
            </Route>
            <Route exact path="/voting">
              <Voting />
            </Route>
            <Route exact path="/voting/proposal/create">
              <CreateProposal />
            </Route>
            <Route path="/voting/proposal/:id">
              <Proposal />
            </Route> */}

          {/* NFT */}

          {/* closed */}
          <Route path="/nfts">
            <NFT />
            {/* <ComingSoon /> */}
          </Route>

          {/* closed */}
          {/* <Route path="/launch">
            <Launch />
          </Route> */}

          {/* <Route path="/token">
              <CreateToken />
            </Route> */}

          {/* <Route path="/pancake-squad">
              <PancakeSquad />
            </Route> */}
          {/* Info pages */}
          {/* <Route path="/info">
              <Info />
            </Route> */}

          <Route path="/bab">
            <Bab />
          </Route>

          {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
          <Route exact strict path="/swap" component={Swap} />
          <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
          <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
          <Route exact strict path="/find" component={PoolFinder} />
          {/* closed */}
          <Route exact strict path="/liquidity" component={Liquidity} />
          <Route exact strict path="/create" component={RedirectToAddLiquidity} />
          <Route exact path="/add" component={AddLiquidity} />
          <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
          <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
          <Route exact path="/create" component={AddLiquidity} />
          <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
          <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
          <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
          <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

          {/* Redirect */}
          <Route path="/pool">
            <Redirect to="/liquidity" />
          </Route>
          <Route path="/syrup">
            <Redirect to="/pools" />
          </Route>
          <Route path="/collectibles">
            <Redirect to="/nfts" />
          </Route>
          <Route path="/profile">
            <Redirect to={`${nftsBaseUrl}/profile/${account?.toLowerCase() || ''}`} />
          </Route>

          {/* 404 */}
          <Route component={NotFound} />
        </Switch>
      </SuspenseWithChunkError>
      {/* </Menu> */}
      <MenuMobile />

      <Footer />

      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
