import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TabMenu as UIKitTabMenu, Tab, Flex } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

// margin-top: 40px;
const TabArea = styled(Flex)`
margin-top: 80px;
  max-width: 540px;
  width: 100%;
`

interface TabMenuProps {
  activeIndex: number
}

const TabMenu: React.FC<TabMenuProps> = ({ activeIndex }) => {
  const { t } = useTranslation()

  return (
    <TabArea>
      <UIKitTabMenu activeIndex={activeIndex}>

        <Tab scale="lg" className={`tabMenu__tabButton ${activeIndex === 0 ? 'tab__active' : ''}`} as={Link} to="/swap">{t('Exchange')}</Tab>

        {/* <Tab scale="lg" className={`tabMenu__tabButton ${activeIndex===1? 'tab__active':'' }`}  as={Link} to="/liquidity">{t('Liquidity')}</Tab> */}
        <Tab scale="lg" className={`tabMenu__tabButton ${activeIndex === 1 ? 'tab__active' : ''}`} as={Link} to="/liquidity" >{t('Liquidity')}</Tab>

        {/* tab__to__hide class has been added temporarily */}

      </UIKitTabMenu>
    </TabArea>
  )
}

export default TabMenu
