
import React from 'react';
import { ethers } from "ethers";
import { Heading, Flex, Card, CardBody, CardFooter, UIKitCardHeader, CardHeader, Button, Image, Text, Select, OptionProps } from '@pancakeswap/uikit'

import { networks, contractAddress } from "./networks";
import ContractABI from './contractABI.json';


class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.StartContest = this.StartContest.bind(this);
        this.SelectRandomWinners = this.SelectRandomWinners.bind(this);
        this.DistributeInWinners = this.DistributeInWinners.bind(this);
        this.LoadData = this.LoadData.bind(this);
        this.provider = new ethers.providers.Web3Provider(window.ethereum);
        this.signer = this.provider.getSigner();
        this.contract = new ethers.Contract(
            contractAddress,
            ContractABI,
            this.signer
        );

        this.state = {
            isLoading: true,
            isPaused: true,
            isDistributed: false,
            ContractBalance: 0,
            AllParticipants: [],
            AllWinner: []
        }
        this.Init();
    }

    componentWillUnmount() {
        console.log("Unmounting");
        this.provider.removeListener("block", this.LoadData);
    }

    async LoadData(blockNumber) {
        const isOwner = await this.contract.isOwner();
        if (isOwner) {
            const _isPaused = await this.contract.paused();
            const _ContractBalance = await this.contract.GetContractBalance();
            const _AllParticipants = await this.contract.GetAllParticipantsAddresses();
            const _Winners = await this.contract.GetWinners();
            const _isDistributed = await this.contract.IsRewardDistributed();
            this.setState(

                {
                    isLoading: false,
                    isDistributed: _isDistributed,
                    isPaused: _isPaused,
                    ContractBalance: _ContractBalance,
                    AllParticipants: _AllParticipants,
                    AllWinner: _Winners
                }

            );
        }
    }

    async Init() {
        this.provider.on("block", this.LoadData);
    }

    async DistributeInWinners() {
        const tx = await this.contract.DistributeReward({ value: ethers.utils.parseEther("15") });
        this.setState({ isLoading: true });
        console.log(tx);
    }

    async StartContest() {
        const tx = await this.contract.StartContest();
        this.setState({ isLoading: true });

    }

    async SelectRandomWinners() {
        
        const { AllParticipants } = this.state;
        if (AllParticipants.length >= 3) {
            const unshuffled = AllParticipants;

            const shuffled = unshuffled
                .map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value);


            const selected = shuffled.slice(0, 3);

            const tx = await this.contract.SelectWinners(selected);
            this.setState({ isLoading: true });

        } else {
            alert("There should be atleast 3 participants");
        }

    }

    render() {
        const { isLoading, isPaused, ContractBalance, AllWinner, AllParticipants, isDistributed } = this.state;
        if (isLoading) {
            return (
                <div className="card text-center">
                    <Heading size="xl" fontSize="24px" color='black' textAlign="center"> Loading..</Heading>
                </div>
            )
        }

        return (
            <>
                {
                    (isPaused)
                        ?
                        <div style={{ padding: "32px", width: "80%" }} >
                            <Card>
                                <CardHeader>
                                    <Heading color="white" textAlign="center">
                                        Admin Panel
                                    </Heading>
                                </CardHeader>
                                <CardBody>
                                    <div aria-hidden="true" role="button" style={{ textAlign: 'center' }} onClick={this.StartContest}>
                                        <Button variant="secondary">
                                            Start Contest
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>

                        </div>
                        :
                        <div className="">
                            <Heading color="black" size="xl" textAlign="center">
                                {`Contract Balance is ${ContractBalance / 10000000} HAM`}
                            </Heading>
                            <br />
                            <div style={{ padding: "32px", width: "600px" }} >
                                {
                                    (AllWinner.length >= 3) &&
                                    <>
                                        <Card>
                                            <CardHeader>
                                                <Heading color="white" textAlign="center">
                                                    Winners
                                                </Heading>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    AllWinner.map((d, i) => {
                                                        return (<Text color="white" className="list-group-item">{d}</Text>)
                                                    })
                                                }
                                            </CardBody>
                                        </Card>
                                    </>
                                }
                                <br />
                                {
                                    (AllParticipants.length >= 1) &&
                                    <>
                                        <Card>
                                            <CardHeader>
                                                <Heading color="white" textAlign="center">
                                                    Participants
                                                </Heading>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    AllParticipants.map((d, i) => {
                                                        return (<Text color="white" className="list-group-item">{d}</Text>)
                                                    })
                                                }
                                            </CardBody>
                                        </Card>
                                    </>
                                }
                                <br />
                                {
                    
                                    (AllWinner.length !== 3)
                                        ?
                                        <div aria-hidden="true" role="button" style={{ textAlign: 'center' }} onClick={this.SelectRandomWinners}>
                                            <Button variant="primary">
                                                Select Random Winner
                                            </Button>
                                        </div>
                                        : (!isDistributed) ?
                                        <div aria-hidden="true" role="button" style={{ textAlign: 'center' }} onClick={this.DistributeInWinners}>
                                            <Button variant="primary">
                                                Distribute Prize
                                            </Button>
                                        </div>
                                        : null
                                }
                            </div>


                      
                        </div>

                }
            </>





        )

    }






}

export default Admin;