import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'state/types'
import { getHambabStakingAddress, getHambabTokenAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'

import tokenAbi from 'config/abi/hambabToken.json'
import stakingAbi from 'config/abi/hambabStaking.json'
import { ethers } from 'ethers'

export enum DepositStatus {
  Unknown,
  Submitted,
  Completed,
}

export interface HambabInfo {
  canClaim?: boolean
  claimed?: boolean
  approved?: boolean
  staked?: boolean
  tokenId?: number
  canWithdraw?: boolean
  rewardTime?: number
  maxUsers?: number
  totalUsers?: number
  rewards?: string[]
  maxedOut?: boolean
  rewarded?: boolean
}

export const fetchHambabUserInfo =
  (account: string): AppThunk =>
  async (dispatch) => {
    const token = getHambabTokenAddress()
    const staking = getHambabStakingAddress()

    const tokenCalls = [
      { address: token, name: 'canClaim', params: [account] },
      { address: token, name: 'isClaimed', params: [account] },
      { address: token, name: 'isApprovedForAll', params: [account, staking] },
      { address: token, name: 'tokenIdOf', params: [account] },
    ]
    const stakingCalls = [
      { address: staking, name: 'isDepositsMaxedOut' },
      { address: staking, name: 'getMaxUsers' },
      { address: staking, name: 'getTotalUsers' },
      { address: staking, name: 'getRewards' },
      { address: staking, name: 'canUserWithdraw', params: [account] },
      { address: staking, name: 'getUserWithdrawalTime', params: [account] },
      { address: staking, name: 'depositInfo', params: [account] },
    ]
    const [_claimable, _claimed, _approved, _tokenId] = await multicall(tokenAbi, tokenCalls)
    const [_maxed, _maxUsers, _totalUsers, _rewards, _withdrawable, _wTime, _deposit] = await multicall(
      stakingAbi,
      stakingCalls,
    )

    dispatch(
      setInfo({
        canClaim: _claimable[0],
        claimed: _claimed[0],
        approved: _approved[0],
        staked: _deposit.status === DepositStatus.Submitted,
        tokenId: _tokenId[0].toNumber(),
        canWithdraw: _withdrawable[0],
        rewardTime: _wTime[0].toNumber(),
        maxUsers: _maxUsers[0].toNumber(),
        totalUsers: _totalUsers[0].toNumber(),
        rewards: _rewards.map((v) => ethers.utils.formatEther(v)),
        maxedOut: _maxed[0],
        rewarded: _deposit.status === DepositStatus.Completed,
      }),
    )
  }

interface BabState {
  info?: HambabInfo
}
const initialState: BabState = { info: undefined }

export const babSlice = createSlice({
  name: 'Bab',
  initialState,
  reducers: {
    setInfo: (state, action: PayloadAction<HambabInfo>) => {
      state.info = action.payload
    },
  },
})

// Actions
export const { setInfo } = babSlice.actions

export default babSlice.reducer
