import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Flex, Box, CloseIcon, IconButton, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { usePhishingBannerManager } from 'state/user/hooks'


const Container = styled(Flex)`
  height: 100%;
  overflow: hidden;
  padding: 12px;
  align-items: center;
  background: #ae5d00;
  
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0px;
    background: #ae5d00;
  }
  `
  // background: linear-gradient(180deg, #8051d6 0%, #492286 100%);

const InnerContainer = styled(Flex)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  `

const SpeechBubble = styled.div`
  background: rgba(39, 38, 44, 0.4);
  border-radius: 16px;
  padding: 8px;
  width: 60%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & ${Text} {
    flex-shrink: 0;
    margin-right: 4px;
  }
`

const PhishingWarningBanner: React.FC = () => {
  const { t } = useTranslation()
  const [, hideBanner] = usePhishingBannerManager()
  const { isMobile, isMd } = useMatchBreakpoints()
  const warningText = t("please make sure you're visiting https://swaphamster.io - check the URL carefully.")
  const warningTextAsParts = warningText.split(/(https:\/\/swaphamster.io)/g)

  const closePhishing = localStorage.getItem("closedPhisingWarning");
  const [isShown, setIsShown] = useState(false);
  useEffect(()=>{
    if(closePhishing === "true"){
      setIsShown(true)
    }
  },[closePhishing])
  function handleCloseBanner () {
    setIsShown(true)
    localStorage.setItem("closedPhisingWarning","true")
  }

  const warningTextComponent = (
    <>
      <Text as="span" color="warning" small bold textTransform="uppercase">
        {t('Phishing warning: ')}
      </Text>
      {warningTextAsParts.map((text) => (
        <Text
          small
          as="span"
          bold={text === 'https://swaphamster.io'}
          color={text === 'https://swaphamster.io' ? '#00ff4e' : '#f8f8f8'}
        >
          {text}
        </Text>
      ))}
    </>
  )
  return (
      <>
        {isShown ? <></> : <Container>
          {isMobile || isMd ? (
            <>
              <Box>{warningTextComponent}</Box>
              {/* <IconButton onClick={hideBanner} variant="text"> */}
              <IconButton onClick={handleCloseBanner} variant="text">
                <CloseIcon color="#FFFFFF" />
              </IconButton>
            </>
          ) : (
            <>
              <InnerContainer>
                <img src="/images/top_section.png" alt="phishing-warning" width="92px" style={{transform:"scaleX(-1)"}} />
                <SpeechBubble>{warningTextComponent}</SpeechBubble>
              </InnerContainer>
              {/* <IconButton onClick={hideBanner} variant="text"> */}
              <IconButton onClick={handleCloseBanner} variant="text">
                <CloseIcon color="#FFFFFF" />
              </IconButton>
            </>
          )}
        </Container>
      }
      </>
  )
}

export default PhishingWarningBanner
